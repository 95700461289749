
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface register {
  name: string;
  email: any;
  phone: string;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
  referenceFrom: string;
}

interface items {
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;

  errorname: string;
  erroremail: string;
  errorphone: string;
  errorprovince: string;
  errorcity: string;
  errorregency: string;
  errordetail: string;
  errorreferenceFrom: string;
}

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading, 
  },

  setup() {

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;
    
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const toast = useToast();

    let items = reactive<items>({
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],

      errorname: "",
      erroremail: "",
      errorphone: "",
      errorprovince: "",
      errorcity: "",
      errorregency: "",
      errordetail: "",
      errorreferenceFrom: "",
    });

    const submitButton = ref<HTMLElement | null>(null);

    const register = reactive<register>({
      name: "",
      email: route.params.email,
      phone: "",
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
      referenceFrom: "",
    });

    const onSubmitRegister = (values) => {
      if (
        register.name === "" ||
        register.phone === "" ||
        register.address.province === "" ||
        register.address.city === "" ||
        register.address.regency === "" ||
        register.address.detail === "" ||
        register.referenceFrom === ""
      ) {
        items.errorname = "Nama Wajib Diisi";
        items.errorphone = "Nomor Telepon Wajib Diisi";
        items.errorprovince = "Provinsi Wajib Diisi";
        items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        items.errorregency = "Kecamatan Wajib Diisi";
        items.errorreferenceFrom = "Referensi Wajib Diisi";
        items.errordetail = "Alamat Wajib Diisi";
      } else {
        items.errorname = "";
        items.errorphone = "";
        items.errorprovince = "";
        items.errorcity = "";
        items.errorregency = "";
        items.errorreferenceFrom = "";
        items.errordetail = "";

        checkDuplicat();

      }
    };

    const checkDuplicat = () => {
      isLoading.value = true;

      ApiService.postWithoutSlug(
        "crmv2/main_auth/auth/register/duplicate_data_check?schoolEmail=" + route.params.email + "&schoolPhone=" + register.phone + "&schoolName=" + register.name
      )
        .then((res) => {
          localStorage.setItem("register_account", JSON.stringify(register));
          router.push("/sign-up/input-password");

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail)

          isLoading.value = false;
        })
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      isLoading.value = true;

      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === register.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug("https://api.katalis.info/wilayah/getRegencies/" + provId)
          .then((response) => {
            items.kabupatenOptions = response.data;
            isLoading.value = false;
          })
          .catch((e) => {
            isLoading.value = false;
          });
      }
    };

    const getKecamatan = () => {
      isLoading.value = true;

      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === register.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug("https://api.katalis.info/wilayah/getDistricts/" + kabupatenId)
          .then((response) => {
            items.kecamatanOptions = response.data;
             isLoading.value = false;
          })
          .catch((e) => {
            isLoading.value = false;
          });
      }
    };

    const check_otp_register = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_auth/auth/register/check_otp_register/" +
          route.params.otp +
          "/" +
          route.params.regId +
          "/" +
          route.params.email
      )
        .then((res) => {})
        .catch((e) => {});
    };

    onMounted(() => {
      check_otp_register();

      getProvinsi();
    });

    return {
      onSubmitRegister,
      submitButton,
      register,
      items,
      check_otp_register,
      getKabupaten,
      getKecamatan,
      checkDuplicat,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
